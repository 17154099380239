
import { computed, defineComponent, PropType } from 'vue';
import Item from '../../../../../domain/Item';
import InventoryCategory from '@/domain/InventoryCategory';
import BTable from '../../../../../components/bootstrap-library/table/BTable/BTable.vue';
import { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export type InventoryPutAwayHistory = {
    item: Item;
    quantity: number;
    inventoryCategory: InventoryCategory
}

type TableData = {
    item: string;
    quantity: number;
    inventoryCategory: string
}

export default defineComponent({
    name: 'put-away-history',
    components: { BTable },
    props: {
        history: { type: Array as PropType<Array<InventoryPutAwayHistory>>, required: true },
    },
    setup(props) {
        const fields: Array<BTableField<TableData>> = [
            { key: 'item', label: getTitleCaseTranslation('core.domain.item') },
            { key: 'inventoryCategory', label: getTitleCaseTranslation('core.domain.inventoryCategoryShort') },
            { key: 'quantity', label: getTitleCaseTranslation('core.domain.quantity') },
        ];

        function mapToTableData(history: Array<InventoryPutAwayHistory>): Array<TableData> {
            return history.map((data) => ({
                item: data.item.name,
                quantity: data.quantity,
                inventoryCategory: data.inventoryCategory.description,
            }));
        }

        const tableData = computed((): Array<TableData> => mapToTableData(props.history));

        return {
            tableData,
            fields,
        };
    },
});
