
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import Shipment from '@/domain/Shipment';
import TagListener from '@/modules/floortrak/services/TagListener';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import ItemPicker from '@/components/ItemPicker.vue';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import InventoryCategory, { InventoryCategorySelectItem } from '@/domain/InventoryCategory';
import CoreStore from '@/store/CoreStore';
import InventoryCategoryQtySelector from '@/modules/floortrak/view/receiving/components/InventoryCategoryQtySelector.vue';
import { InventoryPutAwayHistory } from './PutAwayHistory.vue';
import PutAwayHistory from '@/modules/floortrak/view/receiving/components/PutAwayHistory.vue';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import { InventoryCategoryQtySelectorEmitData } from '@/modules/floortrak/types/WarehouseMovementData';
import FloortrakReceivingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakReceivingTagScanHandler';
import useReceivingSearch from '@/modules/floortrak/composables/useReceivingSearch';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import LabelGenerationService from '@/services/LabelGenerationService';
import { useNotification } from '@/composable/useNotifications';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

export interface KeyValuePair {
    key: any;
    value: any;
}

type State = {
    loading: boolean;
    search: number | null;
    shipment: Shipment;
    showItemSearch: boolean;
    showSearch: boolean;
    searching: boolean;
    puttingAway: number | null;
    showHistory: boolean;
    history: Array<InventoryPutAwayHistory>;
    putAwayQtys: Array<KeyValuePair>;
    currentQtys: Array<KeyValuePair>;
    tags: Array<TrackedItem>;
    movableInventoryCategories: Array<InventoryCategorySelectItem>;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    unloadComplete: boolean;
    showPostUnloadSafetyInspection: boolean;
    postUnloadSafetyInspection: SafetyInspection;
    carriers: Array<Carrier>;
};

interface ItemTableData {
    item: Item;
    name: string;
    estimatedQuantity: number;
    actualQuantity: number;
    putAwayQuantity: number;
    tags: Array<TrackedItem>;
    imageUrlThumb: string | undefined;
    imageUrlFull: string | undefined;
}

export default defineComponent({
    name: 'put-away',
    components: {
        Thumbnail,
        PutAwayHistory,
        InventoryCategoryQtySelector,
        BSpinner,
        BFormInput,
        ItemPicker,
        BTable,
        TrackedItemTagModal,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        BModal,
        BRow,
        TextInput,
        BButton,
        BForm,
        SafetyInspectionQuestionnaire,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const labelGenerationService = new LabelGenerationService();
        const { inventoryCategoryStore } = CoreStore.getInstance();
        const notification = useNotification();
        const { setLoading } = useLoading();
        const safetyInspectionService = new SafetyInspectionService();
        const carrierService = new CarrierService();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            loading: false,
            search: null,
            shipment: new Shipment(),
            showItemSearch: false,
            showSearch: true,
            searching: false,
            puttingAway: null,
            showHistory: false,
            history: [],
            putAwayQtys: [],
            tags: [],
            currentQtys: [],
            movableInventoryCategories: inventoryCategoryStore.getMovableListItems(),
            labelQuantity: undefined,
            showPrintLabelsModal: false,
            printingLabels: false,
            unloadComplete: false,
            showPostUnloadSafetyInspection: false,
            postUnloadSafetyInspection: new SafetyInspection(),
            carriers: [],
        });

        const tagListener = new TagListener(searchItem);
        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );
        const tags = computed((): Array<TrackedItem> => state.tags as Array<TrackedItem>);

        function getCurrentTagsForItem(item: Item): Array<TrackedItem> {
            return state.tags.filter((tag) => tag.item.id === item.id);
        }

        function getPutAwayQuantity(item: Item): number {
            return state.putAwayQtys.find((record) => record.key === item.id)?.value || 0;
        }

        function getCurrentQtyForItem(item: Item) {
            return state.currentQtys.find((record) => record.key === item.id)?.value;
        }

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                name: line.item.name,
                estimatedQuantity: line.estimatedQuantity,
                actualQuantity: line.actualQuantity,
                tags: getCurrentTagsForItem(line.item),
                putAwayQuantity: getPutAwayQuantity(line.item),
                currentQuantity: getCurrentQtyForItem(line.item),
                imageUrlFull: line.item.imageUrlFull,
                imageUrlThumb: line.item.imageUrlThumb,
            }));
        }

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (transaction.value && transaction.value.transactionLines) {
                    return mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>);
                }
                return [];
            },
        );

        function updateCurrentQtyForItem(itemId: number, action: (record: KeyValuePair) => void) {
            const index = state.currentQtys.findIndex((record) => record.key === itemId);
            if (index >= 0) {
                action(state.currentQtys[index]);
            }
        }

        function decrementCurrentQtyForItem(itemId: number) {
            updateCurrentQtyForItem(itemId, (record) => record.value--);
        }

        function addNewCurrentQtyEntry(itemId: number) {
            state.currentQtys.push({ key: itemId, value: 0 });
        }

        function incrementCurrentQtyForItem(itemId: number) {
            updateCurrentQtyForItem(itemId, (record) => record.value++);
        }

        function addTag(trackedItem: TrackedItem): void {
            const itemAlreadyAdded = tableData.value.find((item) => item.item.id === trackedItem.item.id);
            if (!itemAlreadyAdded) {
                transaction.value.addTrackedItem(trackedItem);
                addNewCurrentQtyEntry(trackedItem.item.id);
            }
            incrementCurrentQtyForItem(trackedItem.item.id);
            state.tags.push(trackedItem);
        }

        const tagHandler = new FloortrakReceivingTagScanHandler(transaction, tags, addTag);

        const fields: Array<BTableField<ItemTableData & { action: string }>> = [
            {
                key: 'imageUrlThumb',
                label: getTitleCaseTranslation('core.common.image'),
                width: '70px',
                ignoreSort: true,
            },
            {
                key: 'name',
                width: '300px',
                label: getTitleCaseTranslation('core.domain.name'),
            },
            {
                key: 'estimatedQuantity',
                width: '150px',
                label: getTitleCaseTranslation('core.common.estimatedQuantityShort'),
            },
            {
                key: 'putAwayQuantity',
                width: '150px',
                label: getTitleCaseTranslation('core.common.putAway'),
            },
            {
                key: 'action',
                label: getTitleCaseTranslation('core.common.quantityShort'),
                width: '500px',
            },
        ];

        async function searchItem(barcodes: Array<string>) {
            await tagHandler.performSearch(barcodes);
        }

        function closeSearch() {
            state.search = null;
            state.showSearch = false;
        }

        function refreshPutAwayQtysForItem(itemId: number, qty: number) {
            const itemIndex = state.putAwayQtys.findIndex((record) => record.key === itemId);
            if (itemIndex >= 0) {
                state.putAwayQtys[itemIndex] = { key: itemId, value: qty };
            } else {
                state.putAwayQtys.push({ key: itemId, value: qty });
            }
        }

        function refreshAllPutAwayQtys() {
            transaction.value.transactionLines.forEach((line) => {
                refreshPutAwayQtysForItem(line.item.id, line.actualQuantity);
            });
        }

        function initCurrentQtysForItems() {
            transaction.value.transactionLines.forEach((line) => {
                const index = state.currentQtys.findIndex((record) => record.key === line.item.id);
                if (index >= 0) {
                    state.currentQtys[index].value = 0;
                } else {
                    state.currentQtys.push({ key: line.item.id, value: 0 });
                }
            });
        }

        function postSearchSuccess(shipment: Shipment) {
            state.shipment = shipment;
            initCurrentQtysForItems();
            refreshAllPutAwayQtys();
            closeSearch();
        }

        const { fetchReceivingShipment } = useReceivingSearch(postSearchSuccess);

        async function searchExisting() {
            if (state.search) {
                state.searching = true;
                await fetchReceivingShipment(state.search, ReceivingAction.PUT_AWAY);
                state.searching = false;
                
                state.unloadComplete = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(state.shipment.id!, SafetyInspectionType.PostUnload);
            }
        }

        onBeforeMount(async () => {
            tagListener.startListener();
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });

            state.loading = true;

            if (!forkliftCertified) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationMissing'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            else if(!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationExpired'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            state.loading = false;

            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }
            const carriers = await carrierService.getAllCarriers();
            if (carriers.success) {
                state.carriers = carriers.carriers;
            }
        });

        onBeforeUnmount(() => tagListener.stopListener());

        function removeTag(tag: TrackedItem) {
            transaction.value.removeTrackedItem(tag);
            decrementCurrentQtyForItem(tag.item.id);
            const stateTagIndex = state.tags.findIndex((x) => x.id === tag.id);
            state.tags.splice(stateTagIndex, 1);
        }

        function addItem(data: { item: Item }) {
            const itemAlreadyAdded = transaction.value.transactionLines.find((line) => line.item.id === data.item.id);
            if (!itemAlreadyAdded) {
                transaction.value.addEmptyLine(data.item);
                addNewCurrentQtyEntry(data.item.id);
            }
            incrementCurrentQtyForItem(data.item.id);
            state.showItemSearch = false;
        }

        function clearOutCurrentQtysForItem(itemId: number) {
            updateCurrentQtyForItem(itemId, (record) => {
                record.value = 0;
            });
        }

        function inventoryQtyChange(row: ItemTableData, qty: number) {
            var tags = getCurrentTagsForItem(row.item);
            if (qty < tags.length && tags.length > 0) {
                notification.showError(`${getTranslation('core.validation.qtyCannotBeLessThanTotalTagsForItem', qty)} (${tags.length})`);
            }
            else {
                const index = state.currentQtys.findIndex((record) => record.key === row.item.id);
                if (index >= 0) {
                    state.currentQtys[index].value = qty;
                }
            }
        }

        function mapUpdatedLinesOverShipment(curTransaction: Transaction) {
            const shipmentLines = state.shipment.transactions[0].transactionLines;
            const linesNotYetSaved = shipmentLines.filter((line) => !curTransaction.getTransactionLineByItem(line.item));
            const linesToDisplay = [...linesNotYetSaved, ...curTransaction.transactionLines];
            state.shipment.transactions[0].clearTransactionLines();
            state.shipment.transactions[0].transactionLines.push(...linesToDisplay);
        }

        async function putAway(data: InventoryCategoryQtySelectorEmitData) {
            if (data.item.id && data.toInventoryCategoryId) {
                const tagsForItem = state.tags.filter((tag) => tag.item.id === data.item.id);
                state.puttingAway = data.item.id;

                const { updatedTransaction } = await receivingService.submitPutAway({
                    itemId: data.item.id,
                    quantity: data.quantity,
                    inventoryCategoryId: data.toInventoryCategoryId,
                    transactionId: transaction.value.id,
                    tags: tagsForItem,
                });

                if (updatedTransaction) {
                    mapUpdatedLinesOverShipment(updatedTransaction);

                    tagsForItem.forEach((tag) => {
                        const stateTagIndex = state.tags.findIndex((stateTag) => stateTag.id === tag.id);
                        state.tags.splice(stateTagIndex, 1);
                    });

                    const actualQuantity = updatedTransaction.getTransactionLineByItem(data.item)?.actualQuantity;
                    const updatedPutAwayQty = actualQuantity !== undefined ? actualQuantity : data.quantity;

                    clearOutCurrentQtysForItem(data.item.id);
                    refreshPutAwayQtysForItem(data.item.id, updatedPutAwayQty);

                    state.history.unshift({
                        item: data.item,
                        inventoryCategory: inventoryCategoryStore.getById(data.toInventoryCategoryId) ?? new InventoryCategory(),
                        quantity: data.quantity,
                    });
                }
                state.puttingAway = null;
            }
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function showHistoryModal() {
            state.showHistory = true;
        }

        function openItemSearch() {
            state.showItemSearch = true;
        }

        async function onCloseout() {
            await router.push({
                name: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                params: { transactionNumber: transaction.value.id },
            });
        }

        function openPrintLabelsModal(): void {
            state.showPrintLabelsModal = true;
        }

        async function printLabels() {
            state.showPrintLabelsModal = false;

            if (!state.labelQuantity) {
                notification.showError(getTranslation('core.validation.quantityMustBeSpecified'));
                return;
            }

            state.printingLabels = true;
            const labelUrl = await labelGenerationService.getInboundLabelsUrl(transaction.value.id, state.labelQuantity!);
            state.printingLabels = false;
            state.labelQuantity = undefined;
            if (labelUrl) {
                window.location.href = labelUrl;
            }
        }

        async function printInboundReceipt() {
            state.loading = true;
            setLoading(true);
            await receivingService.getTransactionReceipt(transaction.value.id);
            setLoading(false);
            state.loading = false;
        }

        function showPostUnloadSafetyInspection() {
                state.showPostUnloadSafetyInspection = true
        }

        async function unloadComplete(inspection: SafetyInspection) {
                state.postUnloadSafetyInspection = inspection;
                state.unloadComplete = true;
                await safetyInspectionService.associateShipmentWithTrailerInspection(state.shipment.id!, state.postUnloadSafetyInspection.id);
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            addItem,
            openItemSearch,
            tableData,
            searchExisting,
            putAway,
            escape,
            showHistoryModal,
            getCurrentTagsForItem,
            removeTag,
            inventoryQtyChange,
            onCloseout,
            getTranslation,
            getTitleCaseTranslation,
            printInboundReceipt,
            openPrintLabelsModal,
            printLabels,
            unloadComplete,
            showPostUnloadSafetyInspection,
            SafetyInspectionType,
        };
    },
});
