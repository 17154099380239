import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-042de002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inventory-category-qty-selector" }
const _hoisted_2 = { class: "select-group" }
const _hoisted_3 = {
  key: 0,
  class: "select-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_quantity_picker, {
      disabled: _ctx.disableQtyInput,
      "model-value": _ctx.modelValue,
      min: _ctx.minValue,
      onChange: _ctx.change
    }, null, 8, ["disabled", "model-value", "min", "onChange"]),
    _renderSlot(_ctx.$slots, "tags", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.toCategoryLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(_ctx.toCategoryLabel), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_b_form_select, {
          modelValue: _ctx.state.toInventoryCategoryId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.toInventoryCategoryId) = $event)),
          options: _ctx.toOptions,
          class: "category-select"
        }, null, 8, ["modelValue", "options"])
      ])
    ]),
    _createVNode(_component_b_button, {
      disabled: _ctx.disableButton,
      variant: "primary",
      onClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')) + " ", 1),
        (_ctx.busy)
          ? (_openBlock(), _createBlock(_component_b_spinner, {
              key: 0,
              small: ""
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}