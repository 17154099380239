import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c05a186c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "put-away-table-container" }
const _hoisted_2 = { class: "container-header" }
const _hoisted_3 = { style: {"padding":"10px 0"} }
const _hoisted_4 = { class: "put-away-table-empty" }
const _hoisted_5 = { class: "put-away-table-footer" }
const _hoisted_6 = { style: {"padding-top":"20px","display":"flex","justify-content":"flex-end"} }
const _hoisted_7 = { class: "container-count" }
const _hoisted_8 = { style: {"display":"flex"} }
const _hoisted_9 = { style: {"margin-right":"5px"} }
const _hoisted_10 = { style: {"margin-right":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_TrackedItemTagModal = _resolveComponent("TrackedItemTagModal")!
  const _component_inventory_category_qty_selector = _resolveComponent("inventory-category-qty-selector")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_floor_trak_orbis_card = _resolveComponent("floor-trak-orbis-card")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_put_away_history = _resolveComponent("put-away-history")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_floor_trak_orbis_card, {
      title: _ctx.getTitleCaseTranslation('core.common.putAway'),
      class: "content-body"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_safety_inspection_questionnaire, {
          modelValue: _ctx.state.showPostUnloadSafetyInspection,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showPostUnloadSafetyInspection) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.PostUnload,
          shipment: (_ctx.state.shipment as Shipment),
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          onPassed: _ctx.unloadComplete
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onPassed"]),
        _createVNode(_component_b_row, { class: "put-away-head" }, {
          default: _withCtx(() => [
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.shipment.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.transaction.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.transactionWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.fromLocation.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transaction.fromLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.from'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.toLocation.name,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.transaction.toLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.to'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.dockName,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.shipment.dockName) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.dockDoor'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              "model-value": _ctx.state.shipment.carrierName,
              label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["model-value", "label", "loading"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_b_button, {
              variant: "primary",
              disabled: _ctx.state.history.length === 0,
              onClick: _ctx.showHistoryModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.history')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createVNode(_component_b_table, {
            items: _ctx.tableData,
            fields: _ctx.fields,
            "row-height": "64px"
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(estimatedQuantity)": _withCtx((row) => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(row.item.estimatedQuantity || '-'), 1)
            ]),
            "cell(putAwayQuantity)": _withCtx((row) => [
              _createElementVNode("span", null, _toDisplayString(row.item.putAwayQuantity || '-'), 1)
            ]),
            "cell(action)": _withCtx((row) => [
              _createVNode(_component_inventory_category_qty_selector, {
                key: row.item.item.id,
                "inventory-category-options": _ctx.state.movableInventoryCategories,
                "model-value": row.item.currentQuantity,
                item: row.item.item,
                "min-value": row.item.putAwayQuantity * -1,
                disabled: _ctx.state.puttingAway === row.item.item.id,
                busy: _ctx.state.puttingAway === row.item.item.id,
                onConfirm: _ctx.putAway,
                onChange: (qty) => _ctx.inventoryQtyChange(row.item, qty)
              }, _createSlots({ _: 2 }, [
                (row.item.tags.length > 0)
                  ? {
                      name: "tags",
                      fn: _withCtx(() => [
                        _createVNode(_component_TrackedItemTagModal, {
                          tags: row.item.tags,
                          title: row.item.name,
                          onRemoveTag: _ctx.removeTag
                        }, null, 8, ["tags", "title", "onRemoveTag"])
                      ])
                    }
                  : undefined
              ]), 1032, ["inventory-category-options", "model-value", "item", "min-value", "disabled", "busy", "onConfirm", "onChange"])
            ]),
            empty: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.scanOrAddToStart')), 1)
            ]),
            _: 1
          }, 8, ["items", "fields"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_b_button, {
              class: "btn-add-item",
              onClick: _ctx.openItemSearch
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.add')), 1),
                _createVNode(_component_faicon, { icon: "box-open" })
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showItemSearch,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_item_picker, {
          "item-type": _ctx.ItemType.RECEIVING,
          "allow-quantity-change": false,
          "ignore-combined-containers": "",
          onOnSelect: _ctx.addItem
        }, null, 8, ["item-type", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showHistory,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.showHistory) = $event)),
      centered: "",
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.common.history'),
      size: "xl"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, "* " + _toDisplayString(_ctx.getTranslation('core.common.onlyHistoryForThisSession')) + " *", 1),
        _createVNode(_component_put_away_history, {
          history: _ctx.state.history
        }, null, 8, ["history"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showSearch,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.showSearch) = $event)),
      "disable-escape-key": "",
      "hide-footer": "",
      "hide-header-close": "",
      title: _ctx.getTitleCaseTranslation('core.common.putAway'),
      onEnter: _ctx.searchExisting
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "close-search-modal",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.escape && _ctx.escape(...args)))
        }, [
          _createVNode(_component_faicon, { icon: "times" })
        ]),
        _createVNode(_component_b_form, { onSubmit: _ctx.searchExisting }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              modelValue: _ctx.state.search,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.search) = $event)),
              autofocus: "",
              type: "number",
              placeholder: _ctx.getTitleCaseTranslation('core.common.scanOrLookupTransactionNumber')
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_b_button, {
                type: "submit",
                disabled: _ctx.state.searching
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.search')) + " ", 1),
                  (_ctx.state.searching)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        small: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showPrintLabelsModal,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.showPrintLabelsModal) = $event)),
      title: _ctx.getTranslation('core.common.numberOfLabelsToPrint'),
      onEnter: _ctx.printLabels
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.printingLabels,
          onClick: _ctx.printLabels
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.print')) + " ", 1),
            (_ctx.state.printingLabels)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form_input, {
          modelValue: _ctx.state.labelQuantity,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.labelQuantity) = $event)),
          placeholder: _ctx.getTranslation('core.common.numberOfLabelsToPrint'),
          autofocus: "",
          type: "number"
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_smart_trak_footer, { "content-direction": "space-between" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_ctx.transaction.totalItems) + " " + _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.totalItems')), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, [
            _createVNode(_component_b_button, {
              disabled: _ctx.state.unloadComplete,
              onClick: _ctx.showPostUnloadSafetyInspection
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.unloadComplete')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_b_dropdown, {
              title: _ctx.getTitleCaseTranslation('core.common.print'),
              "close-on-click": "",
              "drop-up": "",
              "no-caret": "",
              "open-on-hover": "",
              "is-button": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_dropdown_item, { onClick: _ctx.printInboundReceipt }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.inboundReceipt')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_b_dropdown_item, { onClick: _ctx.openPrintLabelsModal }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.labels')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_b_button, {
              class: "btn-submit",
              disabled: !_ctx.state.unloadComplete,
              onClick: _ctx.onCloseout
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTranslation('core.common.proceedToCloseout')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}