import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_table = _resolveComponent("b-table")!

  return (_openBlock(), _createBlock(_component_b_table, {
    items: _ctx.tableData,
    fields: _ctx.fields,
    style: {"min-height":"500px"}
  }, null, 8, ["items", "fields"]))
}