
import {
    computed, defineComponent, PropType, reactive,
} from 'vue';
import InventoryCategory, { InventoryCategorySelectItem } from '@/domain/InventoryCategory';
import QuantityPicker from '@/components/QuantityPicker.vue';
import Item from '@/domain/Item';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { InventoryCategoryQtySelectorEmitData } from '@/modules/floortrak/types/WarehouseMovementData';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    fromInventoryCategoryId: number | null;
    toInventoryCategoryId: number | null;
    fromLabel: string | null;
};

export default defineComponent({
    name: 'inventory-category-qty-selector',
    components: {
        BSpinner,
        QuantityPicker,
        BFormSelect,
        BButton,
    },
    props: {
        item: {
            type: Object as PropType<Item>,
            default: undefined,
        },
        inventoryCountsForItem: {
            type: Object as PropType<InventoryGroupedDTO>,
            default: undefined,
        },
        fromCategoryLabel: {
            type: String,
            default: '',
        },
        toCategoryLabel: {
            type: String,
            default: '',
        },
        disableQtyInput: { type: Boolean, default: false },
        minValue: {
            type: Number,
            default: 0,
        },
        disabled: { type: Boolean, default: () => false },
        busy: { type: Boolean, default: () => false },
        inventoryCategoryOptions: {
            type: Array as PropType<Array<InventoryCategorySelectItem>>,
            default: () => [],
        },
        modelValue: {
            type: Number,
            default: 0,
        },
    },
    emits: ['confirm', 'update:modelValue', 'change'],
    setup(props, context) {
        const state = reactive<State>({
            fromInventoryCategoryId: null,
            toInventoryCategoryId: null,
            fromLabel: props.fromCategoryLabel || null,
        });

        const fromOptions = computed(
            (): Array<InventoryCategorySelectItem> => {
                if (state.toInventoryCategoryId !== null) {
                    return props.inventoryCategoryOptions.filter((option) => option.value !== state.toInventoryCategoryId);
                }
                return props.inventoryCategoryOptions;
            },
        );

        const toOptions = computed(
            (): Array<InventoryCategorySelectItem> => {
                if (state.fromInventoryCategoryId !== null) {
                    return props.inventoryCategoryOptions.filter((option) => option.value !== state.fromInventoryCategoryId);
                }
                return props.inventoryCategoryOptions;
            },
        );

        function getOnHandQtys(fromCategory: InventoryCategory | null) {
            let qty = 0;
            if (fromCategory && props.inventoryCountsForItem) {
                qty = props.inventoryCountsForItem.getInventoryForCategory(fromCategory);
            }
            return qty;
        }

        function emitData() {
            context.emit('confirm', {
                quantity: props.modelValue,
                fromInventoryCategoryId: state.fromInventoryCategoryId,
                toInventoryCategoryId: state.toInventoryCategoryId,
                item: props.item,
            } as InventoryCategoryQtySelectorEmitData);
        }

        function cleanUpForm() {
            state.fromInventoryCategoryId = null;
            state.toInventoryCategoryId = null;
        }

        async function handleClick() {
            emitData();
            cleanUpForm();
        }

        function change(qty: number) {
            context.emit('update:modelValue', qty); // updates v-model
            context.emit('change', qty);
        }

        function fromCategoryChange(value: InventoryCategory) {
            if (props.inventoryCountsForItem && props.fromCategoryLabel) {
                const availableQty = getOnHandQtys(value);
                state.fromLabel = getTranslation('core.common.moveFromAvailableWithQuantity', availableQty);
            }
        }

        const disableButton = computed(() => {
            if (props.disabled) {
                return true;
            }

            return props.modelValue === 0 || !state.toInventoryCategoryId;
        });

        return {
            handleClick,
            disableButton,
            state,
            change,
            fromOptions,
            toOptions,
            fromCategoryChange,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
